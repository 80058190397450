import {
  Box,
  Heading,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { navigate } from "gatsby"

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  return (
    <Flex
      alignItems="center"
      // pt={16}
      pt={1}
      mt={1}
      display={{ base: "flex", sm: "flex", md: "none" }}
      justifyContent="space-around"
    >
      <Box w="4rem" h="4rem">
        <StaticImage
          src={"../images/gatsby-icon.png"}
          alt="headerMindfulnessCapital"
          placeholder="blurred"
        />
      </Box>
      <Box as="span" fontSize="1.5rem" fontWeight="900">
        YQX Building Renovation
      </Box>
      <Box>
        <Flex alignItems="center" fontSize={{ base: "xs", md: "md" }}>
          <Box h="20px" mx={2} />
          <Flex
            alignItems="center"
            w="30px"
            h="30px"
            cursor="pointer"
            onClick={onOpen}
          >
            <StaticImage
              src="../../images/ham.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              width={30}
            />
          </Flex>
        </Flex>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent bgColor="#020034">
            <DrawerBody py={87} px={5}>
              <Flex justifyContent="space-between">
                <Box w={2} />
                <Box cursor="pointer" onClick={onClose}>
                  <StaticImage
                    src="../images/close.png"
                    alt="A dinosaur"
                    placeholder="blurred"
                    layout="fixed"
                  />
                </Box>
              </Flex>

              <Box as="nav">
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                  onClick={onClose}
                >
                  Home
                </Box>
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                >
                  <AnchorLink href="#About" onClick={onClose}>
                    Our Services
                  </AnchorLink>
                </Box>
                <Box
                  textAlign="center"
                  color="#fff"
                  fontWeight="600"
                  fontSize="4xl"
                  mt={16}
                  cursor="pointer"
                >
                  <a href="mailto:chao.li@usask.ca">Email Us</a>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </Flex>
  )
}

export default Header
