import { Box, Grid, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const AboutUs = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8} mb={20}>
      {/* title  */}
      <section id="About">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0">
            Our
          </Box>
          &nbsp;Services
        </Heading>
      </section>
      {/* Basement */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        <StaticImage
          src="https://images.pexels.com/photos/7166926/pexels-photo-7166926.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt="A dinosaur"
          placeholder="blurred"
        />

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Basement
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            We professional in bespoke basement finishing and can construct your
            ideal in-law suite. Whether you want a sophisticated design or a
            warm open concept expansion, we can handle all of your basement
            finishing needs.
          </Text>
        </Box>
      </Grid>

      {/* Improvement */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        <StaticImage
          src="https://images.pexels.com/photos/9052547/pexels-photo-9052547.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt="A dinosaur"
          placeholder="blurred"
        />

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Improvement
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            Whether you live in an old farmhouse or a community with mid-century
            design homes, contemporary home improvements can update many aspects
            of your home. You can preserve the original style while our
            experienced team helps you achieve a new and modern look for your
            home with current improvements.
          </Text>
        </Box>
      </Grid>

      {/* Roofing */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        <StaticImage
          src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt="A dinosaur"
          placeholder="blurred"
        />
        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Roofing
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            For over a decade, we’ve been offering roofing services to
            residential, commercial, and industrial clients. We consider
            ourselves experts in all forms of roofing, including metal, clad,
            clay, flat roofing, slate, and any other. Our Regina roofers are
            skilled and experienced in practically any roofing service,
            including roof repairs, roof replacement, siding, and eaves and
            cladding.
          </Text>
        </Box>
      </Grid>
      {/* Landscaping */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        <StaticImage
          src="https://images.pexels.com/photos/259588/pexels-photo-259588.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt="A dinosaur"
          placeholder="blurred"
        />

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Landscaping
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            Our skilled Landscaping and Yard Beautification service will save
            you time when it comes to making your yard look beautiful. Allow our
            home renovations skilled pros to manage your gardening needs so you
            don’t have to worry about finding the correct crew to trim your yard
            to perfection.
          </Text>
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutUs
